import React from "react"
import Header from "../header/Header"
import Main from "../main/Main"
import Footer from "../footer/Footer"
import "../../utils/Vars.css"
import "../../utils/Normalizer.css"
import "./Page.css"
import Seo from "../Seo"

export default props => {
  return (
    <div className={`page ${props.class}`}>
      <Seo title={props.title} slug={props.slug} cover={props.cover} />
      <Header />
      <Main children={props.children} />
      <Footer />
    </div>
  )
}
