import React, { useState } from "react"
import { Link } from "gatsby"
import "./Menu.css"

export default () => {
  const [open, setOpen] = useState(false)

  function openMenu() {
    setOpen(true)
  }

  function closeMenu() {
    setOpen(false)
  }
  return (
    <div className={`menu ${open ? "is-open" : "is-close"}`}>
      <div className="menu-buttons">
        <button onClick={openMenu} className="menu-open">
          <i className="material-icons">menu</i>
        </button>
        <button onClick={closeMenu} className="menu-close">
          <i className="material-icons">close</i>
        </button>
      </div>
      <div className="menu-panel">
        <nav className="nav">
          <Link to="/" className="nav-item" activeClassName="active">
            Inicio
          </Link>
          <Link to="/bio/" className="nav-item" activeClassName="active">
            Bio
          </Link>
          <Link
            to="/opinion/"
            className="nav-item"
            getProps={({ isPartiallyCurrent }) =>
              isPartiallyCurrent ? { className: "nav-item active" } : null
            }
          >
            Opinión
          </Link>
          <Link
            to="/articulos-academicos/"
            className="nav-item"
            activeClassName="active"
          >
            Artículos Académicos
          </Link>
          <Link to="/libros/" className="nav-item" activeClassName="active">
            Libros
          </Link>
          <Link to="/ensenanza/" className="nav-item" activeClassName="active">
            Enseñanza
          </Link>
          <Link
            to="/actividades/"
            className="nav-item"
            activeClassName="active"
          >
            Actividades
          </Link>
        </nav>
      </div>
    </div>
  )
}
