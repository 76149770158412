import React from "react"
import Menu from "../menu/Menu"
// import SocialIcon from "../social/SocialIcon"
import "./NavBar.css"

export default class NavBar extends React.Component {
  render() {
    return (
      <div className="nav-bar">
        <Menu />
        {/* <div className="breadcrumbs">
          <span>Inicio</span>
        </div> */}
        {/* <div className="social-list">
          <SocialIcon name="facebook" isLink url="http://www.facebook.com" />
          <SocialIcon name="twitter" isLink url="http://www.twitter.com" />
        </div> */}
      </div>
    )
  }
}
