import React from "react"
import { Link } from "gatsby"
// import Logo from '../images/logo.svg'
// import Iso from '../images/iso.svg'
import "./Brand.css"

export default ({ type, isLink }) => {
  const brand = {
    // logo: <Logo className={type} />,
    // iso: <Iso className={type} />,
    logo: <div className={type}>NICOLÁS LYNCH</div>,
    iso: <div className={type}>NL</div>,
  }[type]
  return isLink ? (
    <Link className="brand" to="/">
      {brand}
    </Link>
  ) : (
    brand
  )
}
