import React from "react"
import Brand from "../brand/Brand"
import Search from "../search/Search"
import "./TopBar.css"

export default () => {
  return (
    <div className="top-bar">
      <Brand type="logo" isLink />
      <Search />
    </div>
  )
}
