import React, { useState, useMemo } from "react"
import { Link } from "gatsby"
import "./Search.css"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom"

export default () => {
  const [open, setOpen] = useState(false)

  function openSearch() {
    setOpen(true)
  }

  function closeSearch() {
    setOpen(false)
  }
  const appId = process.env.GATSBY_ALGOLIA_APP_ID
  const searchKey = process.env.GATSBY_ALGOLIA_API_KEY
  // useMemo prevents the searchClient from being recreated on every render.
  // Avoids unnecessary XHR requests (see https://tinyurl.com/yyj93r2s).
  const searchClient = useMemo(() => algoliasearch(appId, searchKey), [
    appId,
    searchKey,
  ])

  return (
    <div className={`search ${open ? "is-open" : "is-close"}`}>
      <div className="search-buttons">
        <button onClick={openSearch} className="search-open">
          <i className="material-icons">search</i>
        </button>
        <button onClick={closeSearch} className="search-close">
          <i className="material-icons">close</i>
        </button>
      </div>
      <div className="search-panel">
        <InstantSearch searchClient={searchClient} indexName="Articles">
          <SearchBox
            showLoadingIndicator
            translations={{
              placeholder: "Buscar...",
            }}
          />
          <Hits
            hitComponent={(hits) => {
              // console.log("hits:", hits.hit, hits.hit.sys.contentType.sys.id)
              if (hits.hit.fields.slug) {
                const type = hits.hit.sys.contentType.sys.id
                const title = hits.hit.fields.titulo["en-US"]
                const slug = hits.hit.fields.slug["en-US"]
                // const date = hits.hit.fields.fecha["en-US"]
                // const categories = hits.fields.categorias["en-US"]
                return <Link to={`/${type}/${slug}`}>{title}</Link>
              } else {
                return null
              }
            }}
          />
        </InstantSearch>
      </div>
    </div>
  )
}
