import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import "./Footer.css"

export default (edge) => {
  // console.log(edge)
  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  return (
    <footer className="footer">
      <h1 className="title">Más contenidos:</h1>
      <div className="nav-alt">
        <nav className="nav-alt-sections">
          <Link className="nav-alt-item" to="/">
            Inicio
          </Link>
          <Link className="nav-alt-item" to="/bio/">
            Bio
          </Link>
          <Link className="nav-alt-item" to="/opinion/">
            Opinión
          </Link>
          <Link className="nav-alt-item" to="/articulos-academicos/">
            Artículos Académicos
          </Link>
          <Link className="nav-alt-item" to="/libros/">
            Libros
          </Link>
          <Link className="nav-alt-item" to="/ensenanza/">
            Enseñanza
          </Link>
          <Link className="nav-alt-item" to="/actividades/">
            Actividades
          </Link>
        </nav>
        <nav className="nav-alt-tags">
          <Link className="nav-alt-item" to="/categorias/america-latina">
            América Latina
          </Link>
          <Link className="nav-alt-item" to="/categorias/congreso">
            Congreso
          </Link>
          <Link className="nav-alt-item" to="/categorias/constitucion">
            Constitución
          </Link>
          <Link className="nav-alt-item" to="/categorias/corrupcion">
            Corrupción
          </Link>
          <Link className="nav-alt-item" to="/categorias/democracia">
            Democracia
          </Link>
          <Link className="nav-alt-item" to="/categorias/educacion">
            Educación
          </Link>
          <Link className="nav-alt-item" to="/categorias/internacional">
            Internacional
          </Link>
          <Link className="nav-alt-item" to="/categorias/izquierda">
            Izquierda
          </Link>
        </nav>
        <nav className="nav-alt-tags">
          <Link className="nav-alt-item" to="/categorias/medios">
            Medios
          </Link>
          <Link className="nav-alt-item" to="/categorias/movimiento-social">
            Movimiento Social
          </Link>
          <Link className="nav-alt-item" to="/categorias/personajes">
            Personajes
          </Link>
          <Link className="nav-alt-item" to="/categorias/transicion">
            Transición
          </Link>
          <Link className="nav-alt-item" to="/categorias/universidad">
            Universidad
          </Link>
          <Link className="nav-alt-item" to="/categorias/partidos">
            Partidos
          </Link>
          <Link className="nav-alt-item" to="/categorias/economia">
            Economía
          </Link>
          <Link className="nav-alt-item" to="/categorias/politica">
            Política
          </Link>
        </nav>
      </div>
      <div className="copy">©2021 NICOLASLYNCH.PE</div>
    </footer>
  )
}
