import React from "react"
import "./Main.css"
import "./List.css"
import "./Article.css"

export default props => (
  <main className="main">
    <div className="main-content">{props.children}</div>
  </main>
)
